import { type HansardInfo } from '../components/pages/committee-about/committee-interfaces';
import { getAPIBaseUrl, getParentOrigin } from './api';
import { type Location } from 'react-router-dom';

/**
 * formats a phone number to (111) 1111-1111 or +1 (111) 1111-1111
 * @param phoneNumber phone number to format
 */
export function formatPhoneNumber(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) return '';
  if (phoneNumber.length === 10 || phoneNumber.length === 11) {
    let countryCode = '';
    if (phoneNumber.length === 11) {
      countryCode = `+${phoneNumber.substring(0, 1)} `;
    }
    const offset = countryCode ? 1 : 0;
    const areaCode = phoneNumber.substring(0 + offset, 3 + offset);
    const exchangeCode = phoneNumber.substring(3 + offset, 6 + offset);
    const lineNumber = phoneNumber.substring(6 + offset);
    return `${countryCode}(${areaCode}) ${exchangeCode}-${lineNumber}`;
  }
  return phoneNumber;
}

/**
 * capitalizes a string
 * @param str string
 * @returns capitalized version of the string
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * gets the image url from the path or the default image
 * @param path string image path on api
 */
export function getImageUrl(path: string | undefined) {
  const url = path ? getAPIBaseUrl() + '/public' + path : 'https://images.pexels.com/photos/301448/pexels-photo-301448.jpeg?cs=srgb&dl=pexels-pixabay-301448.jpg&fm=jpg'
  return url;
}

/**
 * opens a path in the main web site
 * @param path url path to open in the main web site
 */
export function openLink(path: string) {
  window.open(`${getParentOrigin()}${path}`, '_parent');
}

/**
 * sends the iframe size to the parent
 */
export function sendSizeToParent() {
  sendToParent('resize', {
    height: document.body.scrollHeight
  });
}

/**
 * sends the iframe size to the parent
 */
export function getWindowLocation() {
  sendToParent('location', {});
}

/**
 * sends title to the parent
 * @param title title to be sent
 */
export function sendTitleToParent(title: string) {
  sendToParent('title', {
    title
  });
}

/**
 * sends a link to a video for jw-player
 * @param url  link to open in iframe on parent window
 */
export function sendVideoLinkToParent(url: string) {
  sendToParent('video', {
    url
  });
}

/**
 * sends a breadcrumb to the parent window
 * @param breadcrumb breadcrumb of page to send
 * @param order order of breadcrumb form the back
 */
export function sendBreadcrumbToParent(breadcrumb: string, order?: number) {
  sendToParent('breadcrumb', {
    breadcrumb,
    order: order ?? 0
  });
}

/**
 * sends an event to the parent window
 * @param type type of event to send
 * @param data data object to send
 */
export function sendToParent(type: string, data: any) {
  window.parent.postMessage({
    type,
    data
  }, '*');
}
/**
 * gets the data fro pagination
 * @param items items to paginate
 * @param setItemOffset set state function for setting the item offset
 * @param itemOffset the current item offset
 * @returns the data for pagination
 */
export function getPaginationData<T>(items: T[], setItemOffset: React.Dispatch<React.SetStateAction<number>>, itemOffset: number) {
  const itemsPerPage = 50;
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  let pageCount = Math.ceil(items.length / itemsPerPage);
  if (pageCount === 1) {
    pageCount = 0; // hide pagination
  }
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  return { pageCount, currentItems, handlePageClick }
}

/**
 * fetches the file extension from a string
 * @param filePath string
 * @returns capitalized version of the file extension
 */
export function getFileExtension(filePath: string): string {
  const match = filePath.match(/\.([^.]+)$/);
  return match ? match[1].toUpperCase() : '';
}

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    const existing = previous.get(group) ?? [];
    existing.push(currentItem);
    previous.set(group, existing);
    return previous;
  }, new Map<K, T[]>());

export interface IndexFile extends HansardInfo {
  indexFileAttributeByFileId: {
    sortOrder: number | null
    indexSubtitle: string | null
  } | null
}
/**
 * Sorts index files into the 7 types that are present
 * @param indexFiles array of nodes from the index query
 */
export function sortIndexFiles(indexFiles: IndexFile[]) {
  const sortedByOrder = indexFiles.sort((a, b) => {
    const sortA = a.indexFileAttributeByFileId?.sortOrder ?? 0;
    const sortB = b.indexFileAttributeByFileId?.sortOrder ?? 0;
    return sortA - sortB;
  });

  const confirmedIndices: IndexFile[] = [];
  sortedByOrder.forEach(file => {
    confirmedIndices.push(file);
  });

  return confirmedIndices;
}

export interface CommitteeLinkName {
  name: string
  link: string
}

export interface CommitteeMembership {
  standing: CommitteeNode[]
  special: CommitteeNode[]
  statutory?: CommitteeNode[]
  currentParliament?: ParliamentAndSession
}

interface CommitteeNode {
  committeeByCommitteeId: CommitteeInfo
  committeeChair?: CommitteeMember
  committeeDeputyChair?: CommitteeMember
  committeeConvener?: CommitteeMember
  committeeMembers?: AllCommitteeMembers

}

interface CommitteeInfo {
  id: number
  typeId: number
  name: string
  abbreviation: string
  endDate: string
}

interface CommitteeMember {
  imageBySmallImageId?: {
    description: string
    path: string
  }
  memberByMemberId: {
    id: number
    firstName: string
    lastName: string
  }
}

interface AllCommitteeMembers {
  allMemberParliaments?: {
    nodes: CommitteeMember[]
  }
}

interface ParliamentAndSession {
  parliamentNumber: number
  parliamentAnnotation: string
  sessionNumber: number
  sessionAnnotation: string
}
/**
 * Returns the relevant committees for a member to display on a website
 * @param committeeMembership array of nodes from the index query
 * @param fName first name of the member
 * @param lName last name of the member
 */
export function getRelevantCommittees(committeeMembership: CommitteeMembership, fName: string, lName: string) {
  const parliament = committeeMembership.currentParliament;
  const today = new Date();

  const filterOutCommittees = (committee: CommitteeNode[], statuary: boolean) => {
    return committee
      .filter(({ committeeByCommitteeId, committeeChair, committeeDeputyChair, committeeConvener, committeeMembers }) => {
        const isInDate = !committeeByCommitteeId.endDate || new Date(committeeByCommitteeId.endDate) > today;
        const isInRole = (committeeChair?.memberByMemberId?.firstName === fName && committeeChair?.memberByMemberId?.lastName === lName) ||
          (committeeDeputyChair?.memberByMemberId?.firstName === fName && committeeDeputyChair?.memberByMemberId?.lastName === lName) ||
          (committeeConvener?.memberByMemberId?.firstName === fName && committeeConvener?.memberByMemberId?.lastName === lName);
        const isDirectMember = committeeMembers?.allMemberParliaments?.nodes.some(member => member.memberByMemberId?.firstName === fName && member.memberByMemberId?.lastName === lName);

        return isInDate && (isInRole || isDirectMember);
      })
      .map(({ committeeByCommitteeId }) => {
        const parliamentPart = `${parliament?.parliamentNumber}${parliament?.parliamentAnnotation}parliament`;
        const sessionPart = `${parliament?.sessionNumber}${parliament?.sessionAnnotation}session`;
        const link = statuary
          ? `${getParentOrigin()}/parliamentary-business/committees/${parliamentPart}-${committeeByCommitteeId.abbreviation}`
          : `${getParentOrigin()}/parliamentary-business/committees/${parliamentPart}-${sessionPart}-${committeeByCommitteeId.abbreviation}`;
        return { name: committeeByCommitteeId.name, link };
      });
  }

  return [
    ...filterOutCommittees(committeeMembership.standing, false),
    ...filterOutCommittees(committeeMembership.special, false),
    ...filterOutCommittees(committeeMembership.statutory ?? [], true)];
}

/**
 * gets the committee session location information
 * format anything/parliament/session/abbreviation/thing
 * @param location url location
 * @returns committee session location information
 */
export function getCommitteeSessionLocation(location: Location) {
  const pathArray = location.pathname.split('/');
  const parliament = pathArray[pathArray.length - 4];
  const session = pathArray[pathArray.length - 3];
  const lastPath = pathArray[pathArray.length - 2];
  const lastPathStringArray = lastPath.split('-');
  const commId = lastPathStringArray[lastPathStringArray.length - 1];
  return { parliament, session, commId }
}

/**
 * gets the committee session location information
 * format anything/parliament/session/abbreviation
 * @param location url location
 * @returns committee session location information
 */
export function getCommitteeSessionLocation2(location: Location) {
  const pathArray = location.pathname.split('/');
  const parliament = pathArray[pathArray.length - 3];
  const session = pathArray[pathArray.length - 2];
  const lastPath = pathArray[pathArray.length - 1];
  const lastPathStringArray = lastPath.split('-');
  const commId = lastPathStringArray[lastPathStringArray.length - 1];
  return { parliament, session, commId }
}

/**
 * gets the committee parliament location information
 * format anything/parliament/abbreviation
 * @param location location object
 * @returns committee parliament location information
 */
export function getCommitteeParliamentLocation(location: Location) {
  const pathArray = location.pathname.split('/');
  const parliament = pathArray[pathArray.length - 2];
  const lastPath = pathArray[pathArray.length - 1];
  const lastPathStringArray = lastPath.split('-');
  const commId = lastPathStringArray[lastPathStringArray.length - 1];
  return { parliament, commId }
}

/**
 * gets the committee parliament location information
 * supports a different format /parliament/commId/thing
 * @param location location object
 * @returns committee parliament location information
 */
export function getCommitteeParliamentLocation2(location: Location) {
  const pathArray = location.pathname.split('/');
  const parliament = pathArray[pathArray.length - 3];
  const commId = pathArray[pathArray.length - 2];
  return { parliament, commId }
}

/**
 * creates a map from an array of objects with type T
 * @param arr array to create a map from
 * @param key key to use
 */
export function createMapFromObjectArr<T, K extends keyof T>(arr: T[], key: K): Map<T[K], T> {
  const map = new Map<T[K], T>();
  for (const obj of arr) {
    map.set(obj[key], obj);
  }
  return map;
}

/**
 * formats a date in YYYY-MM-DD
 * @param date date to format
 * @returns  date string in YYYY-MM-DD
 */
export function getDateString(date: Date) {
  const padL = (nr: any, len = 2, chr = '0') => `${nr}`.padStart(2, chr);
  return `${date.getFullYear()}-${padL(date.getMonth() + 1)}-${padL(date.getDate())}`
}

const getDayFromDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return { year, month, day }
}

export const daysEqual = (dateA: Date, dateB: Date) => {
  const { year: yearA, month: monthA, day: dayA } = getDayFromDate(dateA);
  const { year: yearB, month: monthB, day: dayB } = getDayFromDate(dateB);
  return yearA === yearB && monthA === monthB && dayA === dayB;
}

/**
 * gets the ordinal suffix of a number
 * @param i number to get the suffix of
 */
export function ordinalSuffix(i: number) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

/**
 * gets the number from the string that possibly contains it
 * @param inputString string to extract the number from
 * @returns number extracted from the string as a number
 */
export function extractNumbersFromString(inputString: string) {
  const matches = inputString.match(/\d+/g);
  let numArray: number[] = [];
  if (matches) {
    numArray = matches.map(Number);
    if (numArray.length === 1) {
      return numArray[0];
    }
  }
  return 0;
}

/**
 * makes a query string from an object
 * @param params object of parameters
 * @returns query string
 */
export function getQueryString(params: any) {
  const query = Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
  }).join('&');
  if (query.length) {
    return `?${query}`;
  }
  return query;
}

/**
 * @param dateString date from year would be extracted
 */
export function getYearFromDate(dateString: string) {
  const date = new Date(dateString);
  return date.getFullYear();
}

/**
 * get the display string for a committee meeting
 * @param date date to format
 * @returns committee meeting date
 */
export function formatCommitteeDate(date: string) {
  const convertToDateObject = (dateString: string): Date => {
    return new Date(dateString);
  };

  const formatDate = (date: Date, includeTime?: boolean): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: includeTime
    };

    let formattedDate = date.toLocaleDateString('en-US', dateOptions);

    if (includeTime) {
      const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toUpperCase();
      formattedDate = formattedDate.toUpperCase();

      return `${formattedDate}, ${formattedTime}`;
    }

    return formattedDate;
  };

  const convertedDate = formatDate(convertToDateObject(date), false);
  return convertedDate;
}

/**
 * get the display string for a Blues Tag
 * @param date date to format
 * @returns committee meeting date
 */
export function formatBluesDate(date: string) {
  const convertToDateObject = (dateString: string): Date => {
    return new Date(dateString);
  };

  const formatDate = (date: Date, includeTime?: boolean): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: includeTime
    };

    const formattedDate = date.toLocaleDateString('en-US', dateOptions);

    if (includeTime) {
      const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toUpperCase();

      return `${formattedDate} ${formattedTime}`;
    }

    return formattedDate;
  };

  const convertedDate = formatDate(convertToDateObject(date), true);
  return convertedDate;
}

/**
 * opens link in the video player
 * @param event click event for a tag
 */
export function onVideoLink(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  const aTag = event.target as HTMLAnchorElement;
  sendVideoLinkToParent(aTag.href);
}

/**
 * gets the query parameters for session queries
 * @param session session parameter
 * @returns session query parameter if it should be included
 */
export function getSessionParams(session?: string | undefined) {
  const params = session ? { session } : {};
  return params;
}

const BROADCAST_URL = 'https://video.leg.bc.ca/live/play.aspx?Video=$roomname&title=$title&Description=$title&Autostart=True&Mute=False';

export const checkForLiveBroadcast = (link: string) => {
  if (!link) return link;
  const broadcastStrings = ['chamber', 'dougfir', 'birch'];
  const chamberName = broadcastStrings.find((bs) => bs === link.toLowerCase());
  if (chamberName) {
    return BROADCAST_URL.replace('$roomname', chamberName).replace('$title', chamberName).replace('$title', chamberName);
  }
  return link;
}
/**
 * Fromat time in format hh:mm AM/PM
 * @param timeString dateTime string
 * @returns formatted time string
 */
export function formatTime(timeString: string) {
  const date = new Date(timeString);
  const hours = (date.getHours() % 12) || 12;
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
  return `${hours}:${minutesStr} ${ampm}`;
}
